import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../service/local-storage.service';
import {dictionaryEspanol, dictionaryEnglish, dictionaryFrances} from '../../../environments/environment'; 
import { EventEmitterService } from '../../service/event-emitter.service';
import { ChangeMenuNavigateService } from '../../service/change-menu-navigate.service';

@Component({
  selector: 'app-servicios-certificaciones',
  templateUrl: './servicios-certificaciones.component.html',
  styleUrls: ['./servicios-certificaciones.component.css']
})
export class ServiciosCertificacionesComponent implements OnInit {
  titulos;
  
  constructor(public localStore : LocalStorageService, private eventEmitterService: EventEmitterService, private changeMenu: ChangeMenuNavigateService ) { 
    
    if(localStore.getLenguage() == "es" || localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }
    if(localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

  ngOnInit() {
    this.changeMenu.changeLanguageModelAdicionales();
   
    if(this.eventEmitterService.subVarClases == undefined){
      this.eventEmitterService.subVarClases = this.eventEmitterService.invokeEmitterClases.subscribe(() => {
        this.changeLanguage();
      });
    }
  }

  changeLanguage(){

    if(this.localStore.getLenguage() == "es" || this.localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }

    if(this.localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(this.localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

}
