import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { SendMail } from '../../model/SendMail';
import { environment } from '../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import {LocalStorageService} from '../../service/local-storage.service';
import {dictionaryEspanol, dictionaryEnglish, dictionaryFrances} from '../../../environments/environment'; 
import { EventEmitterService } from '../../service/event-emitter.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  showMailMessage: boolean = false;
  myForm: FormGroup;
  message: SendMail;
  titulos;

  constructor(private http: HttpClient, public localStore : LocalStorageService, private eventEmitterService: EventEmitterService ) {
    if(localStore.getLenguage() == "es" || localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }

    if(localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

  ngOnInit() {
    this.message = new SendMail();

    this.myForm = new FormGroup({
      'nombre': new FormControl(),
      'telefono': new FormControl(),
      'email': new FormControl(),
      'mensaje': new FormControl(),
    });

    if(this.eventEmitterService.subVarContact == undefined){
      this.eventEmitterService.subVarContact = this.eventEmitterService.invokeEmitter.subscribe(() => {
        this.changeLanguage();
      });
    }
  }

  enviar(){
    this.message.name = this.myForm.controls['nombre'].value;
    this.message.mail = this.myForm.controls['email'].value;
    this.message.phone = this.myForm.controls['telefono'].value;
    this.message.message = this.myForm.controls['mensaje'].value;

    let suscription = this.postSenderMail(this.message).subscribe(
      data =>{
        console.log(data);
      },
      error => {
        console.log(error);
      }
    );
    
    this.showMailMessage = true;
    //suscription.unsubscribe();
  }

  postSenderMail(messageBody){
    return this.http.post<any>('http://198.12.229.1:8080/login', '{"userName":"admin ", "password":"admin"}');
  }

  changeLanguage(){
    if(this.localStore.getLenguage() == "es" || this.localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }

    if(this.localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(this.localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }
}
