import { Component, OnInit, ViewChild } from '@angular/core';
import {LocalStorageService} from '../../service/local-storage.service';
import {dictionaryEspanol, dictionaryEnglish, dictionaryFrances} from '../../../environments/environment'; 

 
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {
  titulos;
  menuEs: String = "#";
  
  constructor(public localStore : LocalStorageService ) { 
    this.menuEs = "#";
    if(localStore.getLenguage() == "es" || localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }

    if(localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

  ngOnInit() {
  }

  /*changeLanguageEspanol(){
    this.localStore.storeOnLocalStorage('es');
    this.titulos = dictionaryEspanol;
    this.changeComponents();
  }

  changeLanguageEnglish(){
    this.localStore.storeOnLocalStorage('en');
    this.titulos = dictionaryEnglish;
    this.changeComponents();
  }

  changeLanguageFrances(){
    this.localStore.storeOnLocalStorage('fr');
    this.titulos = dictionaryFrances;
    this.changeComponents();
  }

  changeComponents(){
    this.serviceChild.changeLanguage();
    this.contactChild.changeLanguage();
    this.footerChild.changeLanguage();
  }*/

}
