// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  receiverMail: 'luisjimenez196@gmail.com',
  //wsUrl: 'http://wss.vaneteacher.com/api/'
  wsUrl: 'http://localhost:8000/api/'
};

export const dictionaryEspanol = {
  menu_inicio : 'Inicio',
  menu_service : 'Servicios',
  menu_contacto : 'Contacto',
  menu_encuentranos : 'Encuentranos',
  banner_titulo_grande : 'Bienvenidos a VaneTeacher',
  banner_titulo_chicho : 'Cursos de idiomas y muchos más',
  servicio_titulo : 'Nuestros Servicios',
  servicio_clases : 'Clases de Idiomas',
  Servicios_Servicios_Adicionales : 'Adicionales',
  Servicios_traducciones: 'Traducciones',
  Servicios_certificaciones: 'Certificaciones',
  Servicios_corporativo: 'Corporativos',
  contacto_redes: 'Redes Sociales',
  contacto_Contactanos : 'Contactanos',
  contacto_form_nombre : 'Nombre',
  contacto_form_tlf : 'Teléfono',
  contacto_form_email : 'email',
  contacto_form_mensaje : 'mensaje',
  contacto_form_send : 'Enviar Solicitud',
  contacto_form_enviado : 'Se ha enviado el mensaje correctamente',
  encuentranos_titulo : 'Encuéntranos',
  footer_tlf : 'Teléfono',
  footer_redes : 'Redes Sociales',
  footer_designer : 'Sitio Diseñado por ',
  cursos_title: 'Clases de Idiomas',
  cursos_body_level: 'Nuestros cursos están disponibles en los siguientes idiomas: español, inglés y francés, que se dividen en niveles Básico (A1-A2), Intermedio (B1-B2) y Avanzado (C1-C2) siguiendo los estándares del Marco Común Europeo de Referencia (MCER) para las lenguas.',
  cursos_body_areas: 'Asimismo, ofrecemos cursos técnicos/especializados en cualquier área: administración, economía, mercadeo, medicina, etc. Las clases grupales tienen una duración de una hora y media al día dos veces por semana. Las clases individuales o VIP tienen una duración de una hora, dos veces por semana. De igual forma, nos ajustamos a las necesidades de nuestros alumnos.',
  cursos_body_kids: 'Ofrecemos clases de inglés para niños de 6 a 11 años de una forma dinámica y divertida y refuerzo escolar nivel inicial.',
  adicionales_body_certificaciones: 'Preparamos a nuestros alumnos para rendir los diferentes exámenes internacionales tales como los certificados de inglés americano TOEFL y TOEIC; los de inglés británico IELTS y Cambridge. De la misma manera, los preparamos para los exámenes que deben rendir en la facultad para culminar con su carrera universitaria.',
  adicionales_body_traducciones: 'Prestamos servicios de redacción, revisión, edición y traducción de contenidos presentados en formato escrito del español al inglés y francés o viceversa. Por la responsabilidad que nos caracteriza a la hora de realizar un presupuesto tenemos que considerar los siguientes factores: tipo de texto, formato del texto, longitud del texto e idioma. Si usted desea que realicemos un presupuesto, sírvase enviarnos el documento a las direcciones de contacto, incluyendo todos los detalles del encargo de traducción y nosotros le haremos llegar un presupuesto, así como los plazos de entrega en el menor tiempo posible.',
  coorporativo_title:'Formación Corporativa',
  coorporativo_body: 'Como parte de nuestros servicios de formación continua realizamos capacitaciones en inglés o español que buscan mejorar las habilidades de la fuerza laboral de las empresas. El principal objetivo de estos entrenamientos es acelerar la productividad laboral de los empleados con enfoque en objetivos empresariales. Por ejemplo, contamos con talleres cortos de liderazgo, manejo de atención, relaciones interpersonales, autoafirmación y autoestima, resolución de problemas de forma creativa, entre otros. Se otorgan certificados al final del día del taller.',
  coorporativo_body_1:"Nos caracterizamos por: ",
  coorporativo_body_2: "Profesionalismo, responsabilidad, puntualidad en la entrega, confidencialidad absoluta y precios acorde a la calidad de los servicios ofrecidos.",
};

export const dictionaryEnglish = {
  menu_inicio : 'Home',
  menu_service : 'Services',
  menu_contacto : 'Contacts',
  menu_encuentranos : 'Find Us',
  banner_titulo_grande : 'Welcome to VaneTeacher',
  banner_titulo_chicho : 'Language class and more',
  servicio_titulo : 'Our Services',
  servicio_clases : 'Language Classes',
  Servicios_Servicios_Adicionales : 'Additional',
  Servicios_traducciones: 'Translations',
  Servicios_certificaciones: 'Certifications',
  Servicios_corporativo: 'Corporate',
  contacto_redes: 'Social networks',
  contacto_Contactanos : 'Contact us',
  contacto_form_nombre : 'Name',
  contacto_form_tlf : 'Phone',
  contacto_form_email : 'Mail',
  contacto_form_mensaje : 'Message',
  contacto_form_send : 'Send Request',
  contacto_form_enviado : 'The message has been sent successfully',
  encuentranos_titulo : 'Find us',
  footer_tlf : 'Phone',
  footer_redes : 'Social networks',
  footer_designer : 'Website Designed by',
  cursos_title: 'Language Classes',
  cursos_body_level: 'We offer courses in the following languages: Spanish, English and French. These courses comprise Basic (A1-A2), Intermediate (B1-B2) and Advanced (C1-C2) levels of the common language, considering the Common European Framework of Reference for Languages CEFR.',
  cursos_body_areas: 'We also offer technical/ specialized courses of the language chosen in any area: management, economics, marketing, medicine, etc. The length of group classes is one hour and a half, two times per week. Likewise, we adapt to our students needs.',
  cursos_body_kids: 'On the other hand, we have classes for kids aged 6-11 years in a great dynamic and fun way, as well as, reinforcement of preschoolers.',
  adicionales_body_certificaciones: 'We teach our students to make international tests, such as, certifications of American English TOEFL and TOEIC; British English IELTS and Cambridge. Likewise, we prepare our students for university tests, such as the ones requested in some faculties in order to finish their careers.',
  adicionales_body_traducciones: 'We provide services such as the following: writing, revising, editing and translations of written contents from English to Spanish and French or vice versa. Because of the responsibility that characterizes us, when we have to give you an estimate we have to consider the following factors: type of text, format of the text, how long the text is and language. If you wish that we give you an estimate for a translation service, send us the document to our e-mails, including all the details about the translation and we will give you an estimate, and the date to be delivered to you as soon as possible.',
  coorporativo_title:'Corporate Training',
  coorporativo_body: 'As part of our services of continuous training, we provide some workshops in English and Spanish aim to improve the abilities of company’s workforce. The main objective of these trainings is to speed up employees’ work productivity, focused on business goals. For instance, we count on short workshops of leadership, attention management, interpersonal skills, assertiveness and self-confidence, conflict resolution through a creative manner, among others. Certifications are issued at the end of the workshop.',
  coorporativo_body_1:"The words that best describe us: ",
  coorporativo_body_2: "Professionalism, responsibility, punctuality, absolute confidentiality, prices appropriate to the quality of the services offered.",
};

export const dictionaryFrances = {
  menu_inicio : 'Accueil',
  menu_service : 'Services',
  menu_contacto : 'Contact',
  menu_encuentranos : 'Trouvez-Nous',
  banner_titulo_grande : 'Bienvenue à VaneTeacher',
  banner_titulo_chicho : 'Cours de Langues et plus',
  servicio_titulo : 'Nos Services',
  servicio_clases : 'Cours de Langues',
  Servicios_Servicios_Adicionales : 'Supplémentaires',
  Servicios_traducciones: 'Traductions',
  Servicios_certificaciones: 'Certifications',
  Servicios_corporativo: 'Entreprise',
  contacto_redes: 'Réseaux Sociaux',
  contacto_Contactanos : 'Contactez-Nous',
  contacto_form_nombre : 'Votre Nom et Prénom',
  contacto_form_tlf : 'Numéro de Téléphone',
  contacto_form_email : 'Courriel',
  contacto_form_mensaje : 'Message',
  contacto_form_send : 'Envoyez votre formulaire',
  contacto_form_enviado : 'Le message a été envoyé avec succès',
  encuentranos_titulo : 'Trouvez-Nous',
  footer_tlf : 'Téléphone',
  footer_redes : 'Réseaux Sociaux',
  footer_designer : 'Site web conçu par',
  cursos_title: 'Cours de Langues',
  cursos_body_level: 'Nos cours sont disponibles dans les suivant langues étrangères : espagnol, anglais et français, divisé en niveau Élémentaire (A1-A2), Intermédiaire (B1-B2), et Avancé (C1-C2), selon le Cadre Européen Commun de Référence pour les langues CECR.',
  cursos_body_areas: 'Par ailleurs, nous pouvons vous offrir des cours techniques/spécialisés dans tous les domaines : administrations, économie,  marketing, médicine, etc. La duration des cours collectifs est d’une heure et demie par jour, deux fois par semaine.  Les cours individuels ou VIP ont une duration d’une heure, deux fois par semaine. De même, cela dépendra des besoins de nos étudiants.',
  cursos_body_kids: 'On offre des cours de français pour enfants de 6 à 11 ans, de manière dynamique et ludique et pour les niveaux préscolaires renforcement scolaire.',
  adicionales_body_certificaciones: 'Nous proposons aux étudiants des cours pour se préparer aux examens internationaux d’anglais américain TOEFL et TOEIC et ceux d’anglais britannique IELTS et Cambridge. Aussi, on offre entraînement des tests d’anglais pour les étudiants qui vont finir leur carrière universitaire.',
  adicionales_body_traducciones: 'On offre des services de rédaction, révision, édition et traduction de contenus en format écrit et oral de l’espagnol à l’anglais et français ou vice-versa. Pour la responsabilité qui nous caractérise, on prend en considération les facteurs suivants pour vous offrir un devis: le type, le format et la longueur du texte, et la langue à traduire. Si vous en voulez un, vous pouvez nous envoyer le document à nos directions de courrier électronique, avec tous les détails de votre commande de traduction et nous vous ferons parvenir le devis, ainsi que les délais de livraison dans les plus brefs délais.',
  coorporativo_title:'Formation en entreprise',
  coorporativo_body: "Nous proposons des cours de formation continue en anglais ou en espagnol comme partie de nos services, ceux-ci sont offerts à fin d’améliorer la main-d’œuvre des entreprises. L’objectif principal des entraînements est d’accélérer la productivité des travailleurs, centré aux objectifs de l'entreprise. Par exemple, on offre des ateliers courts de leadership, guide à l’attention, relations interpersonnelles, affirmation et estime de soi, résolution de problèmes de manière créative, entre autres. Les certifications sont données à la fin de l’atelier.",
  coorporativo_body_2: "Professionnalisme, responsabilité, ponctualité, totale confidentialité et prix selon la qualité du service offert.",
  coorporativo_body_1:"Notre devise: "
}
