import { Injectable, Inject } from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';



@Injectable()
export class LocalStorageService {
  language = '';
  STORAGE_KEY = 'local_language';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  public storeOnLocalStorage(language: string) : void {
    this.storage.clear();
    this.storage.set(this.STORAGE_KEY, language);
  }

  public getLenguage(){
    return this.storage.get(this.STORAGE_KEY);
  }
}
