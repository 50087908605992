import { Injectable, EventEmitter } from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeEmitter = new EventEmitter();
  invokeEmitterContact = new EventEmitter();
  invokeEmitterServices = new EventEmitter();  
  invokeEmitterEncuentranos = new EventEmitter();
  invokeEmitterClases = new EventEmitter();

  subsVarFooter: Subscription; 
  subVarContact: Subscription;
  subVarServices: Subscription;
  subVarEncuentranos: Subscription;
  subVarClases: Subscription;

  constructor() { }

  cambiarIdioma() {    
    this.invokeEmitter.emit();    
    this.invokeEmitterContact.emit();
    this.invokeEmitterServices.emit();
    this.invokeEmitterEncuentranos.emit();
    this.invokeEmitterClases.emit();
  }  
}
