import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrincipalComponent } from './component/principal/principal.component';
import { FooterComponent } from './component/footer/footer.component';
import { ContactComponent } from './component/contact/contact.component';
import { ServiciosComponent } from './component/servicios/servicios.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { LocalStorageService } from './service/local-storage.service';
import { ServiciosCursosComponent } from './component/servicios-cursos/servicios-cursos.component';
import { ServiciosCertificacionesComponent } from './component/servicios-certificaciones/servicios-certificaciones.component';
import { EncuentranosComponent } from './component/encuentranos/encuentranos.component';
import { ServiceCorporativoComponent } from './component/service-corporativo/service-corporativo.component';

const appRoutes: Routes = [
  { path: '', component: PrincipalComponent },
  { path: 'cursos', component: ServiciosCursosComponent},
  { path: 'adicionales', component: ServiciosCertificacionesComponent},
  { path: 'corporativo', component: ServiceCorporativoComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    FooterComponent,
    ContactComponent,
    ServiciosComponent,
    ServiciosCursosComponent,
    ServiciosCertificacionesComponent,
    EncuentranosComponent,
    ServiceCorporativoComponent
    ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, ),
    NgbModule,
    StorageServiceModule
  ],
  providers: [LocalStorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
