import { Component} from '@angular/core';
import {LocalStorageService} from './service/local-storage.service';
import { EventEmitterService } from './service/event-emitter.service';
import {dictionaryEspanol, dictionaryEnglish, dictionaryFrances} from '../environments/environment'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  titulos;
  title = 'vanesaTeacher';

  constructor(public localStore : LocalStorageService, private eventEmitterService: EventEmitterService ) { 

    if(localStore.getLenguage() == "es" || localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }

    if(localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

  changeLanguageEspanol(){
    this.localStore.storeOnLocalStorage('es');
    this.titulos = dictionaryEspanol;
    this.chanceAll();
  }

  changeLanguageEnglish(){
    this.localStore.storeOnLocalStorage('en');
    this.titulos = dictionaryEnglish;
    this.chanceAll();
  }

  changeLanguageFrances(){
    this.localStore.storeOnLocalStorage('fr');
    this.titulos = dictionaryFrances;
    this.chanceAll();
  }

  chanceAll(){
    this.eventEmitterService.cambiarIdioma();
  }
 
}
