import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../service/local-storage.service';
import {dictionaryEspanol, dictionaryEnglish, dictionaryFrances} from '../../../environments/environment'; 
import { EventEmitterService } from '../../service/event-emitter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  titulos;
  
  constructor(public localStore : LocalStorageService, private eventEmitterService: EventEmitterService ) { 
    if(localStore.getLenguage() == "es" || localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }
    if(localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

  ngOnInit() {
    if(this.eventEmitterService.subsVarFooter == undefined){
      this.eventEmitterService.subsVarFooter = this.eventEmitterService.invokeEmitter.subscribe(() => {
        this.changeLanguage();
      });
    }
  }

  changeLanguage(){

    if(this.localStore.getLenguage() == "es" || this.localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }

    if(this.localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(this.localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }
}
