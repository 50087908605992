import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeMenuNavigateService {
  element: HTMLElement;

  constructor() { }

  changeLanguageModel(){
    this.element = document.getElementById('menuEn') as HTMLElement;
    this.element.setAttribute('href', 'cursos#');

    this.element = document.getElementById('menuEs') as HTMLElement;
    this.element.setAttribute('href', 'cursos#');

    this.element = document.getElementById('menuFr') as HTMLElement;
    this.element.setAttribute('href', 'cursos#');
  }

  changeLanguageModelAdicionales(){
    this.element = document.getElementById('menuEn') as HTMLElement;
    this.element.setAttribute('href', 'adicionales#');

    this.element = document.getElementById('menuEs') as HTMLElement;
    this.element.setAttribute('href', 'adicionales#');

    this.element = document.getElementById('menuFr') as HTMLElement;
    this.element.setAttribute('href', 'adicionales#');
  }

  changeLanguageModelCorporativo(){
    this.element = document.getElementById('menuEn') as HTMLElement;
    this.element.setAttribute('href', 'corporativo#');

    this.element = document.getElementById('menuEs') as HTMLElement;
    this.element.setAttribute('href', 'corporativo#');

    this.element = document.getElementById('menuFr') as HTMLElement;
    this.element.setAttribute('href', 'corporativo#');
  }
}
