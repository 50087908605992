import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../service/local-storage.service';
import {dictionaryEspanol, dictionaryEnglish, dictionaryFrances} from '../../../environments/environment'; 
import { EventEmitterService } from '../../service/event-emitter.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  titulos;
  
  constructor(public localStore : LocalStorageService, private eventEmitterService: EventEmitterService, private router: Router ) { 
    if(localStore.getLenguage() == "es" || localStore.getLenguage() === undefined){
      this.titulos = dictionaryEspanol;
    }

    if(localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

  ngOnInit() {
    if(this.eventEmitterService.subVarServices == undefined){
      this.eventEmitterService.subVarServices = this.eventEmitterService.invokeEmitterServices.subscribe(() => {
        this.changeLanguage();
      });
    }
  }

  changeLanguage(){
    if(this.localStore.getLenguage() == "es" || this.localStore.getLenguage()=== undefined){
      this.titulos = dictionaryEspanol;
    }

    if(this.localStore.getLenguage() == "en"){
      this.titulos = dictionaryEnglish;
    }

    if(this.localStore.getLenguage() == "fr"){
      this.titulos = dictionaryFrances;
    }
  }

  goToClasses(){
    this.router.navigate(["/cursos"]);
  }

  goToAdicionales(){
    this.router.navigate(["/adicionales"]);
  }

  goToCorporativos(){
    this.router.navigate(["/corporativo"]);
  }
}
